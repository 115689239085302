
header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1.5em 4em;

    border-bottom: solid 3px #192077;
    height: 91px;
    box-sizing: border-box;
}

nav {
    display: flex;
    flex-direction: column;

    padding: 1em;

    border-right: solid 3px #192077;
}

.LogoutButton {
    margin-top: auto;
}

.main-container {
    display: flex;
    min-height: calc(100vh - 91px);
}

main {
    padding: 2em;
    width: 100%;
}