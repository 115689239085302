* {
  padding: 0;
  margin: 0;
}

.App {
  color: #383838;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
